<template>
	<div class="network_err">
		<i class="icon"></i>
		<p class="reason">{{ reason }}</p>
		<button class="border_theme text_theme" @click="handleRefreshClick">刷新重试</button>
	</div>
</template>

<script>
	export default {
		props: {
			reason: {
				type: String,
				default: ''
			}
		},
		methods: {
			handleRefreshClick() {
				this.$emit('onRefresh');
			}
		}
	}
</script>

<style scoped>
	.network_err{ padding: 30px 0; }
	.network_err .icon{ display: block;width: 128px;height: 128px;margin: 0 auto;background-image: url(../../assets/network_err.png);background-size: 100% 100%; }
	.network_err .reason{ margin-top: 20px;font-size: 18px;text-align: center;line-height: 30px; }
	.network_err button{ display: block;width: 140px;height: 36px;margin: 20px auto 0;border-radius: 5px;font-size: 16px; }
</style>
