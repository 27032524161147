import Vue from 'vue';
import Router from "vue-router";

Vue.use(Router);

import Launch from "../pages/launch/launch";
import Login from "../pages/login/login";
import Regist from "../pages/regist/regist";
import Bind from "../pages/bind/bind";
import SuccessfulLanding from "../pages/successful_landing/successful_landing.vue";
import SuccessfulLandinga from "../pages/successful_landinga/successful_landinga.vue";

export default new Router({
	mode: 'history',
	routes: [{
			path: '/launch',
			component: Launch
		}, {
			path: '/login',
			component: Login
		}, {
			path: '/regist',
			component: Regist
		}, {
			path: "/bind",
			component: Bind
		}, {
			path: "/successful_landinga",
			component: SuccessfulLandinga
		},
		{
			path: "/successful_landing",
			component: SuccessfulLanding
		}, {
			path: '*',
			redirect: '/launch'
		}
	]
})