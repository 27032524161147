class SafeCache {
	static setData(kj, kt) {
		let cach_data = {
			kj: kj,
			kt: kt
		};
		sessionStorage.setItem('kfDriver_safe', JSON.stringify(cach_data));
	}
	
	static getData() {
		let cache_data = sessionStorage.getItem('kfDriver_safe');
		let final_data = cache_data ? JSON.parse(cache_data) : {};
		return final_data;
	}
}

export default SafeCache;