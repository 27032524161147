<template>
	<div class="bind">
		<!-- 顶部导航 -->
		<navigation title="绑定"></navigation>
		<!-- 顶部导航 end -->
		
		<!-- 姓名 -->
		<div class="base_form_row border_b">
			<div class="in_label">姓名<span style="color: #f00;">*</span></div>
			<input v-model="name" class="in_inp" type="text" placeholder="请输入姓名">
		</div>
		<!-- 姓名 end -->
		
		<!-- 年龄 -->
		<div class="base_form_row border_b">
			<div class="in_label">年龄</div>
			<input v-model="age" class="in_inp" type="text" placeholder="请输入年龄">
		</div>
		<!-- 年龄 end -->
		
		<!-- 驾龄 -->
		<div class="base_form_row border_b">
			<div class="in_label">驾龄</div>
			<input v-model="drive_year" class="in_inp" type="text" placeholder="请输入年数">
		</div>
		<!-- 驾龄 end -->
		
		<!-- 性别 -->
		<div class="base_form_row border_b">
			<div class="in_label">性别</div>
			<div class="in_chips">
				<div class="in_chip" :class="{'in_chip_on': sex == 0}" @click="handleSexClick(0)">男</div>
				<div class="in_chip" :class="{'in_chip_on': sex == 1}" @click="handleSexClick(1)">女</div>
			</div>
		</div>
		<!-- 性别 end -->
		
		<!-- 手机号码 -->
		<div class="base_form_row border_b">
			<div class="in_label">手机号码<span style="color: #f00;">*</span></div>
			<input :value="phone" class="in_inp" type="text" disabled placeholder="请输入手机号码">
		</div>
		<!-- 手机号码 end -->
		
		<!-- 保存并获取报告 -->
		<p class="advice text_theme"><span style="color: #f00;">*</span>建议您绑定微信以获取更多服务</p>
		<!-- 保存并获取报告 end -->
		
		<div class="foot_btns">
			<button class="disabled" style="margin-right: 15px;" @click="handleJumpClick">跳过</button>
			<button class="bg_theme" @click="handleBindClick">绑定</button>
		</div>
		
		<!-- loading -->
		<div v-if="loading" class="base_loading">
			<Loading></Loading>
		</div>
		<!-- loading end -->
	</div>
</template>

<script>
	import Navigation from '../../components/navigation/navigation.vue';
	import Api from '../../api/index.js';
	
	export default {
		mounted() {
			let phone = this.$route.query.phone;
			this.phone = phone || '';
			this.getBindInfo();
		},
		data() {
			return {
				loading: false,
				name: '',
				age: '',
				drive_year: '',
				sex: 0,
				phone: ''
			}
		},
		methods: {
			/*************** @Events ******************/
			// 点击性别
			handleSexClick(sex) {
				this.sex = sex;
			},
			// 点击跳过
			handleJumpClick() {
				this.$router.replace({path: '/successful_landing'});
			},
			// 点击绑定
			handleBindClick() {
				if (!this.nameReg.test(this.name)) {
					this.$dialog.alert({
						title: '提示',
						message: '姓名格式错误！',
						wait(next) {
							next();
						}
					});
					return false;
				}
				if (!this.ageReg.test(this.age)) {
					this.$dialog.alert({
						title: '提示',
						message: '年龄格式错误！',
						wait(next) {
							next();
						}
					});
					return false;
				}
				if (!this.ageReg.test(this.drive_year)) {
					this.$dialog.alert({
						title: '提示',
						message: '驾龄格式错误！',
						wait(next) {
							next();
						}
					});
					return false;
				}
				if (!this.phoneReg.test(this.phone)) {
					this.$dialog.alert({
						title: '提示',
						message: '手机号码格式错误！',
						wait(next) {
							next();
						}
					});
					return false;
				}
				this.updateDriver();
			},
			
			/*************** @Function ******************/
			// 获取绑定信息
			getBindInfo() {
				const that = this;
				that.loading = true;
				Api.getBindInfo().then((res) => {
					if (res.data.success) {
						that.name = res.data.data.uRName;
						that.age = res.data.data.uAge;
						that.drive_year = res.data.data.extraFlag == -1 ? '' : parseInt(res.data.data.driverAge);
						that.sex = res.data.data.uSex ? parseInt(res.data.data.uSex) : 0;
						that.loading = false;
					} else {
						that.loading = false;
						that.$dialog.alert({
							title: '提示',
							message: res.data.message,
							wait(next) { next(); }
						});
					}
				}, (e) => {
					that.loading = false;
					that.$dialog.alert({
						title: '提示',
						message: e.message,
						wait(next) { next(); }
					});
				});
			},
			// 更新驾驶员信息
			updateDriver() {
				const that = this;
				that.loading = true;
				let requestData = {
					age: that.age,
					driverAge: that.drive_year,
					sex: that.sex,
					u_r_name: that.name
				};
				Api.updateDriver(requestData).then((res) => {
					if (res.data.success) {
						that.loading = false;
						that.$dialog.alert({
							title: '提示',
							message: '更新成功！！！',
							wait(next) { 
								that.$router.replace({path: '/successful_landing'});
								next();
							}
						});
					} else {
						that.loading = false;
						that.$dialog.alert({
							title: '提示',
							message: res.data.message,
							wait(next) { next(); }
						});
					}
				}, (e) => {
					that.loading = false;
					that.$dialog.alert({
						title: '提示',
						message: e.message,
						wait(next) { next(); }
					});
				});
			}
		},
		components: {
			Navigation
		}
	}
</script>

<style scoped>
	.bind{ padding: 44px 15px 15px; }
	.bind .advice{ margin-top: 15px; }
	.bind .foot_btns{ display: flex;margin-top: 40px; }
	.bind .foot_btns button{ display: block;flex: 1;height: 36px;border-radius: 5px;font-size: 16px;color: #fff; }
</style>
