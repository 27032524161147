<template>
	<div class="launch">
		<!-- loading -->
		<Loading v-if="request_status == 0"></Loading>
		<!-- loading end -->

		<!-- 请求出错 -->
		<network-err v-if="request_status == -1" :reason="request_reason" @onRefresh="handleRefresh"></network-err>
		<!-- 请求出错 end -->
	</div>
</template>

<script>
	import Launch from '../../utils/launch.js';
	import Api from '../../api/index.js';

	export default {
		mounted() {
			this.init();
		},
		data() {
			return {
				request_status: 0,
				request_reason: '',
				client_type: '',
			}
		},
		methods: {
			/*************** @Events ******************/
			// 刷新重试
			handleRefresh() {
				this.request_status = 0;
				this.login();
			},

			/*************** @Function ******************/
			// 初始化
			init() {

				this.client_type = this.$route.query.client_type || '';
				let deviceId = this.$route.query.deviceId || '';
				let code = this.$route.query.code || '';
				let count = parseInt(this.$route.query.count);
				let unionId = this.$route.query.unionId || '';
				let serverFlag = this.$route.query.serverFlag || '';
				Launch.setData({
					deviceId,
					code,
					count,
					unionId,
					serverFlag
				});
				if (count == 1) { // 直接登录
					this.login();
				} else if (count == 0) {
					window.location.replace(
						`http://fastreg.uflycloud.com/kdsl_q?deviceId=${ deviceId }&unionId=${ unionId }&serverFlag=${ serverFlag }`
					);
				} else {
					this.$router.replace({
						path: '/login'
					});
				}
			},
			// count为1时直接登录
			login() {
				const that = this;
				let cache_data = Launch.getData();
				const requestData = {
					username: cache_data.code,
					password: '',
					grant_type: 'password',
					type: 'user_unified_login_wechat',
					appId: 'wx4d782ee750a9c211',
					deviceId: cache_data.deviceId,
					serverFlag: cache_data.serverFlag
				};
				Api.login(requestData).then((res) => {
					if (res.data.success) {
						if (that.client_type == 'pad') {
							that.$router.replace({
								path: '/successful_landinga'
							});
						} else {
							that.$router.replace({
								path: '/successful_landing'
							});
						}

					} else {
						that.request_status = -1;
						that.request_reason = res.data.message;
					}
				}, (e) => {
					that.request_status = -1;
					that.request_reason = e.message;
				});
			}

		}
	}
</script>

<style scoped>
	.launch {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100vh;
	}
</style>