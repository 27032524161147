import Vue from 'vue';
import App from './App.vue';
import router from './router/index.js';
import Loading from './components/loading/loading.vue';
import NetworkErr from './components/network_err/network_err.vue';
import Confirm from './components/confirm/confirm.vue';
import VueDialogX from 'vue-dialog-x';

Vue.config.productionTip = false;

Vue.use(VueDialogX);

Vue.component('Loading', Loading);
Vue.component('network-err', NetworkErr);
Vue.component('Confirm', Confirm);

Vue.prototype.numReg = /^([1-9]|[1-9]\d+)$/;
Vue.prototype.phoneReg = /^1[3456789]\d{9}$/;
Vue.prototype.verCodeReg = /^\d{4,6}$/;
Vue.prototype.passwordReg = /^[^\s]{6,18}$/;
Vue.prototype.nameReg = /^[\u4E00-\u9FA5]{2,4}$/;
Vue.prototype.ageReg = /^(?:[1-9][0-9]?|1[01][0-9]|120)$/;

new Vue({
	render: h => h(App),
	router
}).$mount('#app');
