<template>
	<div class="confirm">
		<div class="mask_box">
			<div class="t border_b">{{ message }}</div>
			<div class="b">
				<button @click="handleCancelClick">{{ cancelText }}</button>
				<button class="bg_theme" style="color: #fff;" @click="handleConfirmClick">{{ confirmText }}</button>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			message: {
				type: String,
				default: ''
			},
			confirmText: {
				type: String,
				default: '确认'
			},
			cancelText: {
				type: String,
				default: '取消'
			}
		},
		methods: {
			handleCancelClick() {
				this.$emit('onClick', false);
			},
			handleConfirmClick() {
				this.$emit('onClick', true);
			}
		}
	}
</script>

<style scoped>
	.confirm{ display: block;position: fixed;z-index: 199;width: 100%;height: 100%;top: 0;left: 50%;min-width: 320px;max-width: 750px;background: rgba(0, 0, 0, 0.2);transform: translate(-50%, 0); }
	.confirm .mask_box{ position: absolute;width: 70%;top: 50%;left: 50%;border-radius: 10px;background: #fff;transform: translate(-50%, -50%);overflow: hidden; }
	.confirm .mask_box .t{ position: relative;padding: 15px;font-size: 16px; }
	.confirm .mask_box .b{ display: flex; }
	.confirm .mask_box .b button{ flex: 1;height: 44px;font-size: 16px; }
</style>
