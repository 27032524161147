class Launch {
	static setData(data) {
		let cach_data = {
			deviceId: data.deviceId,
			code: data.code,
			count: data.count,
			unionId: data.unionId,
			serverFlag: data.serverFlag
		};
		sessionStorage.setItem('kfDriver_launchInfo', JSON.stringify(cach_data));
	}
	
	static getData() {
		let cache_data = sessionStorage.getItem('kfDriver_launchInfo');
		let final_data = cache_data ? JSON.parse(cache_data) : {};
		return final_data;
	}
}

export default Launch;