import Axios from 'axios';
import SafeCache from '../utils/safe_cache.js';

/* eslint-disable */
// const MIN_URL = 'http://wx.optest.kfksyun.com/';

const MIN_URL = 'https://api00.uflycloud.com/';

// 设置请求超时时间
Axios.defaults.timeout = 10000;

/**
 * @description get请求
 * url{String} 请求路径
 * obj{Object} 请求参数
 * isAuth{Boolean} 是否需要校验
 * */
const getHttp = (url, obj, isAuth) => {
	let getData = "";
	let config = {};
	let request_url = url;
	if (isAuth) {
		config = {
			headers: { 
				'content-type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${ SafeCache.getData().kj }`,
				'accessToken': SafeCache.getData().kt
			},
			method: "get",
			baseURL: '/'
		};
	} else {
		config = {
			headers: { 
				'content-type': 'application/x-www-form-urlencoded',
				'Authorization': `Basic S2ZXZWJBcHA6S2ZXZWJBcHA=`
			},
			method: "get",
			baseURL: '/'
		};
	}
	for (let k in obj) {
		getData += `${ k }=${ obj[k] }&`;
	}
	
	if (getData) {
		getData = `?${ getData }`;
		getData = getData.slice(0, getData.length - 1);
		request_url += getData;
	}
	return new Promise((resolve, reject) => {
		Axios.get(request_url, config).then((res) => {
			resolve(res);
		}).catch((e) => {
			reject({message: e.errMsg});
		});
	});
}

// post请求
const postHttp = (url, obj, isAuth, isJson) => {
	// window.console.log(store.state.kf_web_loginInfo);
	let postData = "";
	let config = {};
	if (isAuth) {
		isJson 
		? (config = {
			headers: { 
				'content-type': 'application/json',
				'Authorization': `Bearer ${ SafeCache.getData().kj }`,
				'accessToken': SafeCache.getData().kt
			},
			method: "post",
			baseURL: '/'
		})
		: (config = {
			headers: { 
				'content-type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${ SafeCache.getData().kj }`,
				'accessToken': SafeCache.getData().kt
			},
			method: "post",
			baseURL: '/'
		});
	} else {
		isJson
		? (config = {
			headers: { 
				'content-type': 'application/json',
				'Authorization': `Basic S2ZXZWJBcHA6S2ZXZWJBcHA=`
			},
			method: "post",
			baseURL: '/'
		})
		: (config = {
			headers: { 
				'content-type': 'application/x-www-form-urlencoded',
				'Authorization': `Basic S2ZXZWJBcHA6S2ZXZWJBcHA=`
			},
			method: "post",
			baseURL: '/'
		});
	}
	if (!isJson) {
		for (let k in obj) {
			postData += `${ k }=${ obj[k] }&`;
		}
		postData && (postData = postData.slice(0, postData.length - 1));
	} else {
		postData = obj;
	}
	return new Promise((resolve, reject) => {
		Axios.post(url, postData, config).then((res) => {
			resolve(res);
		}).catch((e) => {
			reject({message: e.errMsg});
		});
	});
}

// put请求
const putHttp = (url, obj, isAuth, isJson) => {
	let putData = "";
	let config = {};
	if (isAuth) {
		isJson 
		? (config = {
			headers: { 
				'content-type': 'application/json',
				'Authorization': `Bearer ${ SafeCache.getData().kj }`,
				'accessToken': SafeCache.getData().kt
			},
			method: "post",
			baseURL: '/'
		})
		: (config = {
			headers: { 
				'content-type': 'application/x-www-form-urlencoded',
				'Authorization': `Bearer ${ SafeCache.getData().kj }`,
				'accessToken': SafeCache.getData().kt
			},
			method: "post",
			baseURL: '/'
		});
	} else {
		isJson
		? (config = {
			headers: { 
				'content-type': 'application/json',
				'Authorization': `Basic S2ZXZWJBcHA6S2ZXZWJBcHA=`
			},
			method: "post",
			baseURL: '/'
		})
		: (config = {
			headers: { 
				'content-type': 'application/x-www-form-urlencoded',
				'Authorization': `Basic S2ZXZWJBcHA6S2ZXZWJBcHA=`
			},
			method: "post",
			baseURL: '/'
		});
	}
	if (!isJson) {
		for (let k in obj) {
			putData += `${ k }=${ obj[k] }&`;
		}
		putData && (putData = putData.slice(0, putData.length - 1));
	} else {
		putData = obj;
	}
	return new Promise((resolve, reject) => {
		Axios.put(url, putData, config).then((res) => {
			resolve(res);
		}).catch((e) => {
			reject({message: e.errMsg});
		});
	});
}

export default class Api {
	// 发送验证码
	static sendVertifyCode(obj) {
		return postHttp(`${ MIN_URL }no/sendRegisterCode`, obj, false, true);
	}
	
	// 手机号验证码登录
	static login(obj) {
		return postHttp(`${ MIN_URL }oauth/token`, obj, false, false);
	}
	
	// 注册
	static regist(obj) {
		return postHttp(`${ MIN_URL }oauth/token`, obj, false, false);
	}
	
	// 获取绑定信息
	static getBindInfo() {
		return getHttp(`${ MIN_URL }wuser/getDriverInfo`, {}, true);
	}
	
	// 绑定unionid
	static bindUnionid(obj) {
		return postHttp(`${ MIN_URL }wuser/bind/unionId`, obj, true, true);
	}
	
	// 更新驾驶员信息
	static updateDriver(obj) {
		return postHttp(`${ MIN_URL }wuser/driver/info/update`, obj, true, false);
	}
}