<template>
	<div class="navigation">
		<i v-if="showBack" class="back"></i>
		<span class="text_one">{{ title }}</span>
	</div>
</template>

<script>
	export default {
		props: {
			showBack: {
				type: Boolean,
				default: false
			},
			title: {
				type: String,
				default: ''
			}
		}
	}
</script>

<style scoped>
	.navigation{ display: flex;align-items: center;position: fixed;z-index: 99;width: 100%;height: 44px;top: 0;left: 50%;max-width: 750px;min-width: 320px;padding: 0 15px;background-image: url(../../assets/navigation_bg.png);background-size: 100% 100%;transform: translate(-50%, 0);font-size: 18px;color: #fff; }
	.navigation .back{ display: block;width: 10px;height: 18px;margin-right: 10px;background-image: url(../../assets/route_back.png);background-size: 100% 100%; }
	.navigation span{ display: block;flex: 1; }
</style>
