<template>
	<div class="regist">
		<!-- 顶部导航 -->
		<navigation title="注册"></navigation>
		<!-- 顶部导航 end -->
		
		<!-- 姓名 -->
		<div class="base_form_row border_b">
			<div class="in_label">姓名<span style="color: #f00;">*</span></div>
			<input v-model="name" class="in_inp" type="text" placeholder="请输入姓名">
		</div>
		<!-- 姓名 end -->
		
		<!-- 年龄 -->
		<div class="base_form_row border_b">
			<div class="in_label">年龄<span style="color: #f00;">*</span></div>
			<input v-model="age" class="in_inp" type="text" placeholder="请输入年龄">
		</div>
		<!-- 年龄 end -->
		
		<!-- 驾龄 -->
		<div class="base_form_row border_b">
			<div class="in_label">驾龄<span style="color: #f00;">*</span></div>
			<input v-model="drive_year" class="in_inp" type="text" placeholder="请输入年数">
		</div>
		<!-- 驾龄 end -->
		
		<!-- 性别 -->
		<div class="base_form_row border_b">
			<div class="in_label">性别<span style="color: #f00;">*</span></div>
			<div class="in_chips">
				<div class="in_chip" :class="{'in_chip_on': sex == 0}" @click="handleSexClick(0)">男</div>
				<div class="in_chip" :class="{'in_chip_on': sex == 1}" @click="handleSexClick(1)">女</div>
			</div>
		</div>
		<!-- 性别 end -->
		
		<!-- 手机号码 -->
		<div class="base_form_row border_b">
			<div class="in_label">手机号码<span style="color: #f00;">*</span></div>
			<input v-model="phone" class="in_inp" type="text" placeholder="请输入手机号码">
		</div>
		<!-- 手机号码 end -->
		
		<!-- 验证码 -->
		<div class="base_form_row border_b">
			<div class="in_label">验证码<span style="color: #f00;">*</span></div>
			<input v-model="vertify_code" class="in_inp" type="text" placeholder="请输入验证码">
			<button v-if="!vertify_time" class="in_do_btn text_theme border_theme" @click="getVertifyCode">获取验证码</button>
			<button v-if="vertify_time" class="in_do_btn disabled">{{ vertify_time }}秒后重新获取</button>
		</div>
		<!-- 验证码 end -->
		
		<!-- 保存并获取报告 -->
		<button class="submit_btn bg_theme" @click="handleRegistClick">立即注册</button>
		<!-- 保存并获取报告 end -->
		
		<!-- loading -->
		<div v-if="loading" class="base_loading">
			<Loading></Loading>
		</div>
		<!-- loading end -->
	</div>
</template>

<script>
	import Navigation from '../../components/navigation/navigation.vue';
	import Api from '../../api/index.js';
	import Launch from '../../utils/launch.js';
	
	export default {
		mounted() {
			this.vertify_interval = null;
		},
		beforeDestroy() {
			this.vertify_interval && clearTimeout(this.vertify_interval);
		},
		data() {
			return {
				loading: false,
				name: '',
				age: '',
				drive_year: '',
				sex: 0,
				phone: '',
				vertify_code: '',
				vertify_time: 0
			}
		},
		methods: {
			/*************** @Events ******************/
			// 点击性别
			handleSexClick(sex) {
				this.sex = sex;
			},
			// 获取验证码
			getVertifyCode() {
				if (!this.phoneReg.test(this.phone)) {
					this.$dialog.alert({
						title: '提示',
						message: '手机号码格式错误！',
						wait(next) {
							next();
						}
					});
					return false;
				}
				this.sendVertifyCode();
			},
			// 点击注册
			handleRegistClick() {
				if (!this.nameReg.test(this.name)) {
					this.$dialog.alert({
						title: '提示',
						message: '姓名格式错误！',
						wait(next) {
							next();
						}
					});
					return false;
				}
				if (!this.ageReg.test(this.age)) {
					this.$dialog.alert({
						title: '提示',
						message: '年龄格式错误！',
						wait(next) {
							next();
						}
					});
					return false;
				}
				if (!this.ageReg.test(this.drive_year)) {
					this.$dialog.alert({
						title: '提示',
						message: '驾龄格式错误！',
						wait(next) {
							next();
						}
					});
					return false;
				}
				if (!this.phoneReg.test(this.phone)) {
					this.$dialog.alert({
						title: '提示',
						message: '手机号码格式错误！',
						wait(next) {
							next();
						}
					});
					return false;
				}
				if (!this.verCodeReg.test(this.vertify_code)) {
					this.$dialog.alert({
						title: '提示',
						message: '验证码格式错误！',
						wait(next) {
							next();
						}
					});
					return false;
				}
				this.regist();
			},
			
			/*************** @Function ******************/
			// 发送验证码
			sendVertifyCode() {
				const that = this;
				that.loading = true;
				let requestData = {
					phone: that.phone
				};
				Api.sendVertifyCode(requestData).then((res) => {
					if (res.data.success) {
						that.loading = false;
						that.vertify_time = 60;
						that.vertify_interval = setInterval(() => {
							that.vertify_time--;
							if (that.vertify_time <= 0) {
								that.vertify_time = 0;
								clearTimeout(that.vertify_interval);
							}
						}, 1000);
					} else {
						that.loading = false;
						that.$dialog.alert({
							title: '提示',
							message: res.data.message,
							wait(next) { next(); }
						});
					}
				}, (e) => {
					that.loading = false;
					that.$dialog.alert({
						title: '提示',
						message: e.message,
						wait(next) { next(); }
					});
				});
			},
			// 注册
			regist() {
				const that = this;
				that.loading = true;
				let requestData = {
					username: that.phone,
					grant_type: 'password',
					type: 'user_unified_reg_wechat_phone',
					code: that.vertify_code,
					password: '',
					appId: 'wx4d782ee750a9c211',
					deviceId: Launch.getData().deviceId,
					age: that.age,
					driverAge: that.drive_year,
					sex: that.sex,
					unionId: Launch.getData().unionId,
					u_r_name: that.name,
					serverFlag: Launch.getData().serverFlag
				};
				Api.regist(requestData).then((res) => {
					if (res.data.success) {
						that.loading = false;
						that.$dialog.alert({
							title: '提示',
							message: '注册成功！！！',
							wait(next) { 
								that.$router.replace({path: '/successful_landing'});
								next();
							}
						});
					} else {
						that.loading = false;
						that.$dialog.alert({
							title: '提示',
							message: res.data.message,
							wait(next) { next(); }
						});
					}
				}, (e) => {
					that.loading = false;
					that.$dialog.alert({
						title: '提示',
						message: e.message,
						wait(next) { next(); }
					});
				});
			}
		},
		components: {
			Navigation
		}
	}
</script>

<style scoped>
	.regist{ padding: 44px 15px 15px; }
	.regist .submit_btn{ display: block;width: 100%;height: 50px;margin-top: 40px;border-radius: 5px;font-size: 16px;color: #fff; }
</style>
