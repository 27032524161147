<template>
	<div id="app">
		<template v-if="env == 'wx'">
			<keep-alive>
				<router-view v-if="$route.meta.keepAlive"></router-view>
			</keep-alive>
			<router-view  v-if="!$route.meta.keepAlive"></router-view>
		</template>
		<div v-if="env != 'wx'" style="display: flex;height: 100vh;justify-content: center;align-items: center;font-size: 18px;">请用微信扫码体验！！！</div>
	</div>
</template>

<script>
export default {
	created() {
		this.checkEnv();
	},
	mounted() {
		document.getElementById('window_loading').remove();
	},
	data() {
		return {
			env: ''
		}
	},
	methods: {
		/******************** @Function ***************************/
		// 检测当前环境
		checkEnv() {
			let ua = navigator.userAgent.toLowerCase();
			if (ua.match(/MicroMessenger/i) == "micromessenger") {
				this.env = 'wx';
			}
		}
	}
}
</script>

<style>
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, menu, nav, output, ruby, section, summary, time, mark, audio, video, input, textarea, button, select{ box-sizing: border-box;margin: 0;padding: 0;border: none;outline:none;word-break: break-all;background: transparent; }
	html{ font-size: 0; }
	body { background: #f5f5f5;overflow: hidden;font-family: '思源黑体', 'Helvetica Neue', Helvetica, Arial, sans-serif;font-weight: normal;font-size: 14px;color: #333;line-height: 24px; }
	h1,h2,h3,h4,h5,h6,input,button,textarea,th,a,select{ font-size: 14px;color: #333;font-weight: normal; }
	ul,ol,li{ list-style: none; }
	input,button{ outline:none; appearance:none;-webkit-appearance: none;border: none;  }
	textarea{ outline:none;resize: none; }
	button{ position: relative;white-space: nowrap; }
	button.disabled{ background: #c9c9c9;color: #fff; }
	button:focus{ outline: none; }
	button:after{ content: "";display: block;position: absolute;top: 0;left: 0;right: 0;bottom: 0;pointer-events: none;background-color: #ccc;opacity: 0;transition: all .3s; }
	button:active:after{ opacity: .3;transition: 0s; }
	input,button,textarea{ background-color: transparent; }
	a{ text-decoration: none; }
	.clear{ height: 0; line-height: 0; font-size: 0; clear: both;}
	.clearfix:after{content: ""; display: block; height: 0; clear: both; visibility: hidden; font-size: 0;}
	.hide{ display: none!important; }
	
	#app{ display: block;position: relative;min-width: 320px;max-width: 750px;margin: 0 auto;overflow-x: hidden; }
	
	.text_theme{ color: #369FF3!important;}
	
	.bg_theme{ background-color: #369FF3!important; }
	
	.border_theme{ border: 1px solid #369FF3!important; }
	
	.border_t:before{ content: '';display: block;position: absolute;width: 100%;height: 1px;top: 0;left: 0;background: #c9c9c9; }
	.border_r:after{ content: '';display: block;position: absolute;width: 1px;height: 100%;top: 0;right: 0;background: #c9c9c9; }
	.border_b:after{ content: '';display: block;position: absolute;width: 100%;height: 1px;bottom: 0;left: 0;background: #c9c9c9; }
	.border_l:before{ content: '';display: block;position: absolute;width: 1px;height: 100%;top: 0;left: 0;background: #c9c9c9; }
	
	.text_one{ overflow: hidden;text-overflow:ellipsis;white-space: nowrap; }
	.text_two{ display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden; }
	.text_three{ display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 3;overflow: hidden; }
	.text_four{ display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 4;overflow: hidden; }
	.text_five{ display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 5;overflow: hidden; }
	
	.base_form_row{ display: flex;align-items: center;position: relative;height: 46px;padding: 10px 0; }
	.base_form_row .in_label{ display: block;width: 80px;font-size: 17px; }
	.base_form_row .in_inp{ display: block;flex: 1;padding: 0 15px;font-size: 15px;line-height: 26px;overflow: hidden; }
	.base_form_row .in_chips{ display: flex;padding: 0 15px; }
	.base_form_row .in_chip{ margin-right: 15px;padding: 0 15px;border: 1px solid #c9c9c9;border-radius: 2px;font-size: 15px; }
	.base_form_row .in_chip_on{ border: 1px solid #369FF3;color: #369FF3; }
	.base_form_row .in_do_btn{ display: block;height: 26px;padding: 0 8px;border-radius: 5px;font-size: 13px; }
	
	.base_loading{ display: flex;justify-content: center;align-items: center;position: fixed;z-index: 199;width: 100%;height: 100%;top: 0;left: 50%;max-width: 750px;min-width: 320px;transform: translate(-50%, 0);background: rgba(0, 0, 0, 0.2); }
	
	.fade_transition-enter-active,.fade_transition-leave-active {
		transition: opacity .5s ease-out;
	}
	.fade_transition-enter,.fade_transition-leave-to {
		opacity: 0;
	}
</style>
