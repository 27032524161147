<template>
	<div class="successful_landing">
		<img src="../../assets/successful_landing.png" alt="" style="display: block;width: 100%;" @click="handleImgClick">
	</div>
</template>

<script>
	export default{
		methods: {
			handleImgClick() {
				window.WeixinJSBridge.call('closeWindow');
			}
		}
	}
</script>

<style scoped>
	.successful_landing{ min-height: 100vh;background: #fff; }
</style>
