<template>
	<div class="successful_landing">
		<img src="../../assets/successful_landing1.png" alt="" style="display: block;width: 100%;" @click="handleImgClick">
	</div>
</template>

<script>
	export default {
		mounted() {
			document.title = '企业客户5.0版';
		},
		
		
		methods: {


			handleImgClick() {
				window.WeixinJSBridge.call('closeWindow');
			}
		}
	}
</script>

<style scoped>
	.successful_landing {
		min-height: 100vh;
		background: #fff;
	}
</style>