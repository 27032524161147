<template>
	<div class="login">
		<!-- 顶部导航 -->
		<navigation title="登录"></navigation>
		<!-- 顶部导航 end -->
		
		<h1 class="title">视觉健康系统评估</h1>
		
		<h3 class="sub_title">当前门店：合肥市浮山路宝岛眼镜</h3>
		
		<!-- 手机号输入框 -->
		<div class="row border_b" style="margin-top: 36px;">
			<div class="label_icon phone_label_icon border_r"></div>
			<input v-model="phone" type="text" placeholder="请输入手机号">
			<i v-show="phone.length" class="clear_icon" @click="clearPhone"></i>
		</div>
		<!-- 手机号输入框 end -->
		
		<!-- 验证码输入框 -->
		<div class="row border_b" style="margin-top: 5px;">
			<div class="label_icon code_label_icon border_r"></div>
			<input v-model="vertify_code" type="text" placeholder="请输入验证码">
			<button v-if="!vertify_time" class="text_theme border_theme" @click="getVertifyCode">获取验证码</button>
			<button v-if="vertify_time" class="disabled">{{ vertify_time }}秒后重新获取</button>
		</div>
		<!-- 验证码输入框 end -->
		
		<!-- 提交 -->
		<button class="submit_btn bg_theme" @click="handleLoginClick">登 录</button>
		<!-- 提交 end -->
		
		<!-- loading -->
		<div v-if="loading" class="base_loading">
			<Loading></Loading>
		</div>
		<!-- loading end -->
		
		<!-- confirm -->
		<transition name="fade_transition">
			<Confirm v-if="confirm_display" :message="confirm_message" @onClick="handleConfirmClick"></Confirm>
		</transition>
		<!-- confirm end -->
	</div>
</template>

<script>
	import Navigation from '../../components/navigation/navigation.vue';
	import Api from '../../api/index.js';
	import Launch from '../../utils/launch.js';
	import SafeCache from '../../utils/safe_cache.js';
	
	export default {
		mounted() {
			this.kj = '';
			this.kt = '';
			this.vertify_interval = null;
		},
		beforeDestroy() {
			this.vertify_interval && clearTimeout(this.vertify_interval);
		},
		data() {
			return {
				loading: false,
				phone: '',
				vertify_code: '',
				
				vertify_time: 0,
				
				confirm_display: false,
				confirm_message: ''
			}
		},
		methods: {
			/*************** @Events ******************/
			// 清除手机号
			clearPhone() {
				this.phone = '';
			},
			// 点击获取验证码
			getVertifyCode() {
				if (!this.phoneReg.test(this.phone)) {
					this.$dialog.alert({
						title: '提示',
						message: '手机号码格式错误！',
						wait(next) {
							next();
						}
					});
					return false;
				}
				this.sendVertifyCode();
			},
			// 点击提交
			handleLoginClick() {
				if (!this.phoneReg.test(this.phone)) {
					this.$dialog.alert({
						title: '提示',
						message: '手机号码格式错误！',
						wait(next) {
							next();
						}
					});
					return false;
				}
				if (!this.verCodeReg.test(this.vertify_code)) {
					this.$dialog.alert({
						title: '提示',
						message: '验证码格式错误！',
						wait(next) {
							next();
						}
					});
					return false;
				}
				this.login();
			},
			// 点击confirm
			handleConfirmClick(res) {
				if (res) {
					this.bindUnionid();
				} else {
					this.$router.replace({path: `/bind?phone=${ this.phone }`});
				}
			},
			
			/*************** @Function ******************/
			// 发送验证码
			sendVertifyCode() {
				const that = this;
				that.loading = true;
				let requestData = {
					phone: that.phone
				};
				Api.sendVertifyCode(requestData).then((res) => {
					if (res.data.success) {
						that.loading = false;
						that.vertify_time = 60;
						that.vertify_interval = setInterval(() => {
							that.vertify_time--;
							if (that.vertify_time <= 0) {
								that.vertify_time = 0;
								clearTimeout(that.vertify_interval);
							}
						}, 1000);
					} else {
						that.loading = false;
						that.$dialog.alert({
							title: '提示',
							message: res.data.message,
							wait(next) { next(); }
						});
					}
				}, (e) => {
					that.loading = false;
					that.$dialog.alert({
						title: '提示',
						message: e.message,
						wait(next) { next(); }
					});
				});
			},
			// 登录
			login() {
				const that = this;
				that.loading = true;
				let requestData = {
					username: that.phone,
					grant_type: 'password',
					type: 'user_unified_login_wechat_phone',
					code: that.vertify_code,
					password: '',
					appId: 'wx4d782ee750a9c211',
					deviceId: Launch.getData().deviceId,
					serverFlag: Launch.getData().serverFlag
				};
				Api.login(requestData).then((res) => {
					if (res.data.success) {
						that.loading = false;
						that.kj = res.headers.ttkj;
						that.kt = res.headers.ttkt;
						SafeCache.setData(that.kj, that.kt);
						that.confirm_message = '登录成功，是否绑定？';
						that.confirm_display = true;
					} else {
						that.loading = false;
						that.$dialog.alert({
							title: '提示',
							message: res.data.message,
							wait(next) { 
								// console.log(res);
								res.data.code == 407 && (that.$router.replace({path: '/regist'}));
								next();
							}
						});
					}
				}, (e) => {
					// console.log(e.code);
					// console.log(e.status);
					that.loading = false;
					that.$dialog.alert({
						title: '提示',
						message: e.message,
						wait(next) { next(); }
					});
				});
			},
			// 绑定unionId
			bindUnionid() {
				const that = this;
				that.loading = true;
				let requestData = {
					unionId: Launch.getData().unionId
				};
				Api.bindUnionid(requestData).then((res) => {
					if (res.data.success) {
						that.loading = false;
						that.$router.replace({path: `/bind?phone=${ that.phone }`});
					} else {
						that.loading = false;
						that.$dialog.alert({
							title: '提示',
							message: res.data.message,
							wait(next) { next(); }
						});
					}
				}, (e) => {
					that.loading = false;
					that.$dialog.alert({
						title: '提示',
						message: e.message,
						wait(next) { next(); }
					});
				});
			}
		},
		components: {
			Navigation
		}
	}
</script>

<style scoped>
	.login{ padding: 82px 15px 15px; }
	.login .title{ font-size: 15px;color: #000;text-align: center; }
	.login .sub_title{ margin-top: 15px;font-size: 12px;color: #999999;text-align: center;line-height: 22px; }
	.login .row{ display: flex;align-items: center;position: relative;height: 46px;padding: 10px 0; }
	.login .row .label_icon{ position: relative;width: 46px;height: 100%; }
	.login .row input{ display: block;flex: 1;padding: 0 15px;font-size: 15px;line-height: 26px;overflow: hidden; }
	.login .row .clear_icon{ display: block;width: 14px;height: 14px;background-image: url(../../assets/clear.png);background-size: 100% 100%; }
	.login .row button{ display: block;height: 26px;padding: 0 8px;border-radius: 5px;font-size: 13px; }
	.login .row .phone_label_icon{ background-image: url(../../assets/phone_icon.png);background-size: 16px 21px;background-repeat: no-repeat;background-position: center center; }
	.login .row .code_label_icon{ background-image: url(../../assets/vertify_code_icon.png);background-size: 19px 17px;background-repeat: no-repeat;background-position: center center; }
	.login .submit_btn{ display: block;width: 100%;height: 50px;margin-top: 40px;border-radius: 5px;font-size: 16px;color: #fff; }
</style>
